@use '@angular/material' as mat;

$light-text: #ffffff;
$light-primary-text: $light-text;

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);

.tellco-theme {
	--primary-color: #6e615a;
	--primary-lighter-color: #d4d0ce;
	--primary-darker-color: #51453f;
	--text-primary-color: #{$light-primary-text};
	--text-primary-lighter-color: #{$dark-primary-text};
	--text-primary-darker-color: #{$light-primary-text};
}

$mat-primary-tellco: (
	main: #6e615a,
	lighter: #d4d0ce,
	darker: #51453f,
	200: #6e615a,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text,
		),
);

.tellco-theme {
	--accent-color: #8c7f78;
	--accent-lighter-color: #ddd9d7;
	--accent-darker-color: #6f625b;
	--text-accent-color: #{$dark-primary-text};
	--text-accent-lighter-color: #{$dark-primary-text};
	--text-accent-darker-color: #{$light-primary-text};
}

$mat-accent-tellco: (
	main: #8c7f78,
	lighter: #ddd9d7,
	darker: #6f625b,
	200: #8c7f78,
	// For slide toggle,
	contrast:
		(
			main: $dark-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text,
		),
);

.tellco-theme {
	--warn-color: #ff0000;
	--warn-lighter-color: #ffb3b3;
	--warn-darker-color: #ff0000;
	--text-warn-color: #{$light-primary-text};
	--text-warn-lighter-color: #{$dark-primary-text};
	--text-warn-darker-color: #{$light-primary-text};
}

$tellco-theme-primary: mat.define-palette($mat-primary-tellco, main, lighter, darker);

$tellco-theme-accent: mat.define-palette($mat-accent-tellco, main, lighter, darker);
