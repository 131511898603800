@use '@angular/material' as mat;

$light-text: #ffffff;
$light-primary-text: $light-text;

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);

.ecare-theme {
	--primary-color: #2c7250;
	--primary-lighter-color: #c0d5cb;
	--primary-darker-color: #1b5537;
	--text-primary-color: #{$light-primary-text};
	--text-primary-lighter-color: #{$dark-primary-text};
	--text-primary-darker-color: #{$light-primary-text};
}

$mat-primary-ecare: (
	main: #2c7250,
	lighter: #c0d5cb,
	darker: #1b5537,
	200: #2c7250,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text,
		),
);

.ecare-theme {
	--accent-color: #afbfb0;
	--accent-lighter-color: #e7ece7;
	--accent-darker-color: #96a997;
	--text-accent-color: #{$dark-primary-text};
	--text-accent-lighter-color: #{$dark-primary-text};
	--text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent-ecare: (
	main: #afbfb0,
	lighter: #e7ece7,
	darker: #96a997,
	200: #afbfb0,
	// For slide toggle,
	contrast:
		(
			main: $dark-primary-text,
			lighter: $dark-primary-text,
			darker: $dark-primary-text,
		),
);

.ecare-theme {
	--warn-color: #ff0000;
	--warn-lighter-color: #ffb3b3;
	--warn-darker-color: #ff0000;
	--text-warn-color: #{$light-primary-text};
	--text-warn-lighter-color: #{$dark-primary-text};
	--text-warn-darker-color: #{$light-primary-text};
}

$ecare-theme-primary: mat.define-palette($mat-primary-ecare, main, lighter, darker);
$ecare-theme-accent: mat.define-palette($mat-accent-ecare, main, lighter, darker);
