/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzJjNzI1MCIsIj9lcjwjYzBkNWNiIiwiO2VyPCMxYjU1Mzd$LCIlPmBePCNhZmJmYjAiLCI~ZXI8I2U3ZWNlNyIsIjtlcjwjOTZhOTk3fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import 'ecare-color';
@import 'tellco-color';

$fontConfig: (
	display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
	display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
	display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0em),
	display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
	headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0em),
	title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
	subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
	subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
	body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
	body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
	button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
	caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
	input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
	base: black,
	divider: $dark-dividers,
	dividers: $dark-dividers,
	disabled: $dark-disabled-text,
	disabled-button: rgba($dark-text, 0.26),
	disabled-text: $dark-disabled-text,
	elevation: black,
	secondary-text: $dark-accent-text,
	hint-text: $dark-disabled-text,
	accent-text: $dark-accent-text,
	icon: $dark-accent-text,
	icons: $dark-accent-text,
	text: $dark-primary-text,
	slider-min: $dark-primary-text,
	slider-off: rgba($dark-text, 0.26),
	slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
	base: $light-text,
	divider: $light-dividers,
	dividers: $light-dividers,
	disabled: $light-disabled-text,
	disabled-button: rgba($light-text, 0.3),
	disabled-text: $light-disabled-text,
	elevation: black,
	hint-text: $light-disabled-text,
	secondary-text: $light-accent-text,
	accent-text: $light-accent-text,
	icon: $light-text,
	icons: $light-text,
	text: $light-text,
	slider-min: $light-text,
	slider-off: rgba($light-text, 0.3),
	slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
	background: $light-background,
	status-bar: $light-bg-darker-20,
	app-bar: $light-bg-darker-5,
	hover: $dark-bg-alpha-4,
	card: $light-bg-lighter-5,
	dialog: $light-bg-lighter-5,
	tooltip: $dark-bg-tooltip,
	disabled-button: $dark-bg-alpha-12,
	raised-button: $light-bg-lighter-5,
	focused-button: $dark-focused,
	selected-button: $light-bg-darker-20,
	selected-disabled-button: $light-bg-darker-30,
	disabled-button-toggle: $light-bg-darker-10,
	unselected-chip: $light-bg-darker-10,
	disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
	background: $dark-background,
	status-bar: $dark-bg-lighter-20,
	app-bar: $dark-bg-lighter-5,
	hover: $light-bg-alpha-4,
	card: $dark-bg-lighter-5,
	dialog: $dark-bg-lighter-5,
	tooltip: $dark-bg-lighter-20,
	disabled-button: $light-bg-alpha-12,
	raised-button: $dark-bg-lighter-5,
	focused-button: $light-focused,
	selected-button: $dark-bg-lighter-20,
	selected-disabled-button: $dark-bg-lighter-30,
	disabled-button-toggle: $dark-bg-lighter-10,
	unselected-chip: $dark-bg-lighter-20,
	disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

$mat-warn: (
	main: #ff0000,
	lighter: #ffb3b3,
	darker: #ff0000,
	200: #ff0000,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text,
		),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$ecare-theme: (
	primary: $ecare-theme-primary,
	accent: $ecare-theme-accent,
	warn: $theme-warn,
	is-dark: false,
	foreground: $mat-light-theme-foreground,
	background: $mat-light-theme-background,
);

$tellco-theme: (
	primary: $tellco-theme-primary,
	accent: $tellco-theme-accent,
	warn: $theme-warn,
	is-dark: false,
	foreground: $mat-light-theme-foreground,
	background: $mat-light-theme-background,
);
$altTheme: (
	primary: $ecare-theme-primary,
	accent: $ecare-theme-accent,
	warn: $theme-warn,
	is-dark: true,
	foreground: $mat-dark-theme-foreground,
	background: $mat-dark-theme-background,
);

// Theme Init
.ecare-theme {
	@include mat.all-component-themes($ecare-theme);
}

.tellco-theme {
	@include mat.all-component-themes($tellco-theme);
}

.theme-alternate {
	@include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
	padding: 0 1.15em;
	margin: 0 0.65em;
	min-width: 3em;
	line-height: 36.4px;
}

.mat-standard-chip {
	padding: 0.5em 0.85em;
	min-height: 2.5em;
}

.material-icons {
	font-size: 24px;
	font-family: 'Material Icons', 'Material Icons', serif;

	.mat-badge-content {
		font-family: 'Roboto', serif;
	}
}
