@import 'theme';
@import 'styles-mobile';

body {
	--background-color: #f8f9fa;
	--border-color: rgba(0, 0, 0, 0.12);
}

html,
body {
	margin: 0;
	height: 100%;
	font-family: Roboto, sans-serif;
}

.toolbar-line {
	border-bottom: 1px solid var(--border-color);
}

.font-color {
	color: #666666;
}

.form-title {
	margin-bottom: 10px;
	font-weight: bold;
}

.form-content {
	width: 80%;
}

.form-content-center {
	text-align: center;
}

.in-line {
	display: inline-block;
}

.right-10-space {
	padding-right: 10px;
}

.left-10-space {
	padding-left: 10px;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.center {
	margin: auto;
	width: 80%;
	padding: 10px;
}

.responsive {
	width: 100%;
	height: auto;
}

.mat-tooltip {
	font-size: 14px;
}

.mat-tooltip-panel {
	pointer-events: none;
}

.mat-input-element:disabled {
	color: black !important;
}

.section {
	margin-top: 15px;
}

.section-title {
	float: left;
	padding: 0 5px;
	margin: -20px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.card-section-title {
	float: left;
	padding: 0 5px;
	margin: -15px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.section-content {
	margin-left: 5px;
	margin-right: 5px;
}

.card-section {
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 5px;
}

.sub-section {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	padding: 10px;
	margin-top: 15px;
}

.sub-section-no-border {
	padding: 10px;
}

.sub-section-no-title {
	padding: 10px;
	margin-top: 15px;
}

.sub-section-title {
	float: left;
	padding: 0 5px;
	margin: -20px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.sub-section-content {
	margin-left: 5px;
	margin-right: 5px;
}

.option-sub {
	font-size: 12px;
	color: gray;
}

.top-select-margin {
	margin-top: 18px !important;
}

.align-toggle-button-and-input {
	margin-top: 12px;
}

.top-button-margin {
	margin-top: 15px !important;
}

.toggle-button-side-label {
	margin-top: 30px;
	margin-right: 5px;
}

.width-100 {
	width: 100%;
}

.margin-top-25 {
	margin-top: 25px;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-top-10 {
	margin-top: 10px;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap nc-toggle-buttons {
	margin-bottom: 10px;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.readonly-form {
	text-align: left;

	& tr {
		& td:nth-child(1) {
			color: var(--primary-color);
		}

		& td:nth-child(2) {
			font-weight: 500;
		}
	}
}

a {
	color: var(--primary-color);
}

::-webkit-scrollbar-track {
	background: #b7b0ad;
}

::-webkit-scrollbar-thumb {
	background: var(--primary-color);
}

::-webkit-scrollbar {
	width: 2px;
}

.divider-accordions {
	margin-bottom: 50px;
}

.divider-containers {
	margin-bottom: 30px;
}

.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary:hover {
	background: #388d62 !important;
}

.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary.mat-button-disabled.ng-star-inserted:hover {
	background: rgba(44, 44, 44, 0.12) !important;
}

.ea-accordion-item-style {
	border: 0 !important;
	font-weight: 500;
	font-size: 17px;
}

.ea-accordion-body-style {
	font-weight: 400;
	font-size: 14px !important;
}

.button-add-container {
	margin-top: 10px;
	margin-bottom: 10px;
}

.margin-bottom-15 {
	margin-bottom: 15px;
}

.containter-margin-table {
	margin-bottom: 15px;
	margin-top: 10px;
}

.error-container {
	color: red;
	box-shadow: 0 2px 1px -1px rgba(256, 0, 0, 0.2), 0px 1px 1px 0px rgba(256, 0, 0, 0.14), 0px 1px 3px 0px rgba(256, 0, 0, 0.12);
}

::ng-deep .error-border {
	box-shadow: 0 2px 1px -1px rgba(256, 0, 0, 0.2), 0px 1px 1px 0px rgba(256, 0, 0, 0.14), 0px 1px 3px 0px rgba(256, 0, 0, 0.12);
}

.await-story-style {
	border-left: 7px solid var(--primary-color) !important;
}

.edited-story-style {
	border-right: 7px solid orange !important;
}

.mat-badge-content {
	position: absolute;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	transition: transform 200ms ease-in-out;
	transform: scale(0.6);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
	width: 14px;
	height: 14px;
	padding-top: 1px !important;
}

.ea-dialog-message-title {
	font-weight: 600;
	font-size: 16px !important;
}

.ea-dialog-message-text {
	font-size: 16px !important;
}

body .cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%;
	transform: translateX(-16px) translateY(-15px) !important;
}

body .cdk-overlay-pane.mat-datepicker-popup {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%;
	transform: translate(-15px, 25px) !important;
}

.error-message {
	color: red;
}
